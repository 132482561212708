<template>
  <div class="app-container calendar-list-container">
    <div style="padding: 20px">
      <el-table ref="prodTable" :data="prodData" border fit>
        <el-table-column
          label="投票名称"
          prop="name"
          align="center"
          min-width="100"
        />
        <el-table-column
          label="创建时间"
          prop="createdTime"
          align="center"
          min-width="60"
        />
        <el-table-column
          label="截止时间"
          prop="deadline"
          align="center"
          min-width="60"
        />
        <el-table-column
          label="投票状态"
          prop="status"
          align="center"
          min-width="60"
        >
          <template slot-scope="scope">
            {{ ["未发布", "已结束", "发布中"][scope.row.status] }}
          </template>
        </el-table-column>

        <el-table-column label="操作" min-width="60" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="gotoCharts(scope.row)"
              >数据分析</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination
          background
          :current-page="query.page"
          layout="total, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
    </div>
  </div>
</template>

<script>
import axios from "../../../util/extra-api";
import api from "../../../util/api";
import pcAxios from "../../../util/api";
import { local } from "../../../util/util";
import { mapState } from "vuex";
import { mapGetters } from "vuex";
export default {
  filters: {
    // 类型转换
    formatType(type) {
      const TypeMap = {
        1: "输入框",
        2: "单项选择器",
        3: "多项选择器",
      };
      return TypeMap[type];
    },
    formatType1(type) {
      const TypeMap1 = {
        0: "输入与选择",
        1: "用户信息",
        2: "图片上传",
        3: "更多建议",
      };
      return TypeMap1[type];
    },
  },
  data() {
    return {
      templatesId: null,
      goodsId: null,
      tableKey: 0,
      list: [], // 表格数据
      total: null, // 表格数据长度
      listLoading: true, // 加载状态
      tableHeight: 450,
      goodsVisible: false,
      listQuery: {
        // 路由参数
        opt: 1,
        page: 1,
        count: 20,
        id: null,
      },
      listQuery1: {
        opt: 1,
        page: 1,
        count: 20,
      },
      total1: null,
      temp: {
        name: "",
      },
      prod_id: null,
      dialogFormVisible: false, // 弹框显示与否
      dialogStatus: "", // 弹框状态
      textMap: {
        // 弹框头部名称
        update: "编辑",
        create: "添加",
      },
      rules: {
        // 输入框规则
        name: [{ required: true, message: "必填项", trigger: "blur" }],
      },
      dialogVisible: false,
      phoneVisible: false,
      clickRow: null,
      clickRow1: null,
      moveFlag: false,
      moveFlag1: false,
      prodData: [],
      tableData: [],
      showAddTemplate: false,
      query: {
        page: 1,
        limit: 10,
        type: 2,
        keyword: "",
        companyId: "",
      },
      addTempForm: {
        name: "",
        type: 0,
        companyId: "",
        bgImg: "",
      },
      templateTitle: "",
      templateId: null,
      goodsTableData: [],
      goodsTotal: 0,
      currentPage: 1,
      page: {
        pager_offset: "0",
        pager_openset: "10",
        name: "",
        gtin: "",
        goods_type: "",
        type: 0,
      },
      batchPage: {
        // pager_offset: "0",
        // pager_openset: "10",
        page: 1,
        limit: 10,
        goodsId: "",
        templateId: null,
      },
      batchCurrentPage: 1,
      batchTotal: 0,
      batchList: [],
      showGoods: true,
      showAllGoods: true,
      bindQuery: {
        page: 1,
        limit: 10,
        templateId: null,
      },
      bindList: [],
      bindTotal: 0,
    };
  },
  mounted() {
    this.page = Object.assign({}, this.goodsState);
    this.page.company_id = local.get("company_id") + "";
    this.currentPage = this.page.pager_offset / 10 + 1;
    this.getList();
    // this.init(this.page);
  },
  computed: {
    ...mapGetters(["ec"]),
    ...mapState({
      goodsState: (state) => state.goodsManage.goodsState,
    }),
  },
  watch: {
    showAddTemplate(val) {
      if (!val) {
        this.resetAddTemp();
      }
    },
    dialogFormVisible(val) {
      if (!val) {
        this.temp = {
          name: "",
        };
      }
    },
  },
  methods: {
    handleSizeChange(val) {
      this.query.count = val;
      this.getList();
    },
    // 换页
    handleCurrentChange(val) {
      this.query.page = val;
      this.getList();
    },
    gotoCharts(row) {
      // console.log(row);
      this.$router.push({
        name: "poll",
        query: {
          id: row.id,
        },
      });
    },
    getBindGoods() {
      axios
        .get("/v1/pc/evaluation/batch/bindList", this.bindQuery)
        .then((res) => {
          if (res.code) {
            this.bindList = res.data;
            this.bindTotal = res.total;
          }
        });
    },
    async handleBind(row) {
      //   console.log(row, this.templatesId,this.goodsId);
      axios
        .post("/v1/pc/evaluation/batch/bindGoods", {
          templatesId: this.templatesId,
          goodsInfo: [
            {
              goodsId: this.goodsId,
              batchNumber: row.batchNumber,
              batchId: row.id,
            },
          ],
        })
        .then((res) => {
          this.initBatch(this.batchPage).then((res1) => {
            console.log(res1);
            if (res1.code === 200) {
              this.batchList = res1.data;
              this.batchTotal = res1.total;
            }
          });
        });
    },
    getBatch(row) {
      this.goodsId = row.id;
      this.showGoods = false;
      this.getBatchInfo(row);
    },
    onSubmit() {
      this.currentPage = 1;
      this.page.pager_offset = "0";
      this.$store.dispatch("goodsManage/setNewPage", this.page);
      this.init(this.page);
    },
    async batchCurrentChange(page) {
      this.batchCurrentPage = page;
      this.batchPage.page = page;
      let res = await this.initBatch(this.batchPage);

      this.batchList = res.data;
      this.batchTotal = res.total;
    },
    initBatch(params) {
      return new Promise((resolve, reject) => {
        axios
          .get("/v1/pc/evaluation/goods/batchList", params)
          .then((v) => {
            resolve(v);
          })
          .catch((err) => {
            reject(v);
          });
      });
    },
    expandChange(row, expandedRows) {
      if (expandedRows.includes(row)) {
        if (expandedRows.filter((v) => v !== row).length) {
          //   console.log(expandedRows.filter((v) => v !== row));
          this.$refs["goodsTable"].toggleRowExpansion(
            expandedRows.filter((v) => v !== row)[0],
            false
          );
        }
        this.getBatchInfo(row);
      }
    },
    async getBatchInfo(row) {
      this.batchPage.page = 1;
      this.batchPage.goodsId = row.id;
      this.batchPage.templateId = this.templatesId;
      let res = await this.initBatch(this.batchPage);
      console.log(res);
      this.batchList = res.data;
      this.batchTotal = res.total;
    },
    currentChange(page) {
      this.currentPage = page;
      this.page.pager_offset = String((page - 1) * 10);
      this.$store.dispatch("goodsManage/setNewPage", this.page);
      this.init(this.page);
    },
    reInit() {
      this.page.pager_offset = "0";
      this.page.name = "";
      this.page.gtin = "";
      this.page.goods_type = "";
      this.init(this.page);
      this.showAllGoods = true;
    },
    init(params) {
      api.get("/pc/goods/list", params).then((v) => {
        this.goodsTableData = v.data.goods_list.map((m) => {
          let num = "";
          if (m.unit !== "未设置单位!" && m.unit.length > 0) {
            m.unit.forEach((item, index) => {
              if (index !== m.unit.length - 1) {
                num += item.unit_num + item.unit_name + "+";
              } else {
                num += item.unit_num + item.unit_name;
              }
            });
          } else {
            num = "无单位";
          }
          return {
            ...m,
            num,
          };
        });
        this.goodsTotal = v.data.pager_count;
      });
    },
    addTemplate() {
      this.addTempForm.companyId = local.get("company_id");
      if (this.templateTitle == "添加模板") {
        axios.post("/v1/pc/evaluation/save", this.addTempForm).then((res) => {
          if (res.code === 200) {
            this.getList();
            this.showAddTemplate = false;
          }
        });
      } else {
        let data = {
          ...this.addTempForm,
          id: this.templateId,
        };
        axios.post("/v1/pc/evaluation/update", data).then((res) => {
          if (res.code === 200) {
            this.getList();
            this.showAddTemplate = false;
          }
        });
      }
      this.resetAddTemp();
    },
    resetAddTemp() {
      this.addTempForm = {
        companyId: "",
        name: "",
        type: "",
      };
    },
    getList() {
      this.query.companyId = local.get("company_id");
      console.log(this.query, "测试");
      axios.get("/v1/pc/evaluation/list", this.query).then((res) => {
        console.log(res, 123);
        this.tableHeight =
          window.innerHeight - this.$refs.prodTable.$el.offsetTop - 85;
        this.prodData = res.data;
        this.total = res.total;

        // this.prodData = res.data.map((m) => {
        //   return {
        //     ...m,
        //     isDefault: m.isDefault == 1,
        //   };
        // });
        // this.total1 = res.total;
      });
    },
  },
};
</script>
<style lang="scss">
.filter-container {
  padding-bottom: 10px;

  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
  }

  // .phone {
  //   /*!*height: 611px;*!*/
  //   /*width: 360px;*/
  //   /*border-radius: 55px;*/
  //   /*border-radius: 4px;*/
  //   /*box-shadow: 0 0 9px 5px #eee;*/
  //   /*position: relative;*/
  // }
}
.phone .bg {
  width: 360px;
  height: 64px;
  padding-top: 5px;
  background: -webkit-linear-gradient(rgba(55, 55, 55, 0.98), #545456);
  background: linear-gradient(rgba(55, 55, 55, 0.98), #545456);
  text-align: center;
}
.phone iframe {
  border: none;
  width: 360px;
  height: 542px;
  padding: 0;
  box-sizing: border-box;
}
.phone .mask {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  filter: alpha(opacity=0);
  opacity: 0;
  background: #ffffff;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.aic {
  align-items: center;
}
.batch-item {
  background-color: #409eff;
  color: #ffffff;
  border-radius: 10px;
  margin: 10px;
  padding: 10px 20px;
}
.jcb {
  justify-content: space-between;
}
</style>
